.about_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 200px 9vw 0 9vw;
}

.about_title {
  color: #642a8b;
  text-shadow: 1px 1px 1px;
  /* font-size: 55px; */
  margin-bottom: 2rem;
}

.about_text {
  display: flex;
  flex-direction: column;
  /* font-size: 3.5em; */
  color: #561a71;
  text-shadow: .5px .5px .5px;
  letter-spacing: .7px;
  /* max-width: 800px; */
  background-color: rgb(254, 246, 241);
  padding: 3rem;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.288);
  border-radius: 36px;
}

.about_description {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.about_text p:last-child {
  margin-bottom: 0;
}

.about_space {
  height: 13vh;
}

@media (max-width: 850px) {
  .about_container {
    margin: 10px 10px 0 10px;
  }

  .about_text {
    padding: 20px;
  }

  .about_description {
    font-size: 1em;
    margin-bottom: 40px;
  }
}
  