.animal_card {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  background-color: #ffffff;
  height: 525px;
  width: 24.1vw;
  overflow: hidden;
  border: 1px solid #ffffff;
}

.animal_list_wrapper {
  flex-direction: column;
}

.animal_card:hover {
  transform: translateY(-8px);
}

.animal_list_img_container {
  border: 1px solid #ffffff;
}

.animal_img {
  width: 100%;
  height: 315px;
  object-fit: cover;
  border: 1px solid #ffffff;
}

.animal_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .2em 0 .1em 0;
  flex: 1 1 auto;
  margin-bottom: 2px;
  font-size: 17px;
  color: #7834a0f3;
}

.animal_info p {

}

.list_name {
  margin: 0 0 3px 0;
  font-size: 24px;
  font-weight: bold;
  color: #7834a0f3;
}

.list_info p {
  flex-direction: row;
  margin-top: 5px;
  font-size: 18px;
}

.animal_button {
  width: 60%;
  margin-top: 4px;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
  background-color: #fc6e21;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.remove {
  width: 40%;
  margin: 10px 16px;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
  background-color: #d421fc;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.animal_button:hover {
  background-color: #d55b1a;
}

.list-name {
  font-weight: bolder;
}

p {
  margin: 5px 0;
  font-size: 18px;
}

@media (max-width: 767px) {
  .animal_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .animal_card {
    flex-direction: column;
    height: auto;
    width: 70vw;
    margin: 20px 0;
  }

  .animal_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .animal_info {
    padding: 10px;
    text-align: center;
    align-items: center;
  }
  
}
