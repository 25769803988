.landing {
    margin: auto;
    padding-top: 30px;
    font-size: 32px;
    text-shadow: 0 1px 1px #8c869089;
    color: #6c1f96;
    max-width: 40vw;
    font-family: "new-kansas", sans-serif;
    font-weight: 500;
    font-style: normal;   
}

.search_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 200px 0 20vh 5vw;
    /* padding-top: 20px; */
    padding-bottom: 25px;
    width: 90vw;
    background: rgb(242, 234, 229);
    border-radius: 36px;
}

.search_panel {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
}

form {
    padding: 10px;
    margin: 30px;
    background-color: rgb(255, 102, 0);
    border-radius: 17px;
    box-shadow:5px 4px rgba(0, 0, 0, 0.454);
    font-size: calc(10px + 2vmin);
    max-width: 80vw;
}

input {
    margin: 5px;
    padding: 10px;
}

.dropdowns {
    margin: 0 auto;
    width: fit-content;
    padding-top: 10px;
}

.search_button {
    margin-top: 10px;
}

.filler {
    height: 39vh;
}

@media only screen and (max-width: 768px) {
    .landing {
        font-size: 1em;
        max-width: 80vw;
        padding-top: 20px;
        color: rgb(113, 41, 162);
    }

    .filler {
        height: 23vh;
    }

    .dropdowns  {
        justify-content:space-between;
        /* margin: 10px; */
    }

    .search_container {
        margin: 20px 0 2vh 0;
        /* padding-top: 20px; */
        padding-bottom: 25px;
        width: 100vw;
        background: rgb(242, 234, 229);
        border-radius: 0;
    }
    
    .search_panel {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: fit-content;
    }

    .search_button {
        width: 80px;
        margin-left: 39%;
    }

}