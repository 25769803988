.donate {
  display: "flex"; 
  flex-direction:"column";
  justify-content:"center";
}

.donate_container {
  display: flex;
  justify-items: center;
  flex-direction: column;
  padding-bottom: 35vh;
  background-color:rgb(242, 234, 229);
  border-radius: 20px;
  padding: 20px 80px 0 80px;
  margin: 150px 0 100px 10%;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.748);
  width: 80%;
}
  
.donate_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.donate_row:not(:last-child) {
  border-bottom: 2px solid #ccc;
}

.donate_img {
  max-width: 13vw;
}

.donate_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30vw;
  padding: 50px;
}

.donate_link {
  max-width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  padding-bottom: 20px;
  color: #6d238f;
  text-shadow: .5px .5px .5px;
}

.donate_description {
  font-size: 18px;
  color: #561a71;
  text-shadow: .5px .5px .5px;
  letter-spacing: .7px;
  width: 35vw;
}

@media only screen and (max-width: 768px) {
  .donate_container {
    margin: -50px 0 80px 5%;
    width: 90%;
    padding: 10px 0 0 0;
  }

  .donate_row {
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .donate_row:not(:last-child) {
    border-bottom: 2px solid #ccc;
  }

  .donate_text_container,
  .donate_description {
    width: 100%;
  }

  .donate_link {
    width: 95%;
    font-size: 20px;
    margin-top: -30px;
  }

  .donate_img {
    max-width: 30vw;
    margin-top: 20px;
  }
}