.App {
  text-align: center;
  max-width: 100%;
}

.App-header {
  max-height: 5vh;
  /* padding-top: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html body {
  display: flex;
  background-color: #4d5a74;
  flex-direction: column;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  font-family:"new-kansas", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body {
  max-width: 100vw;
}

main {
  flex: 1 0 auto;
}

.petpurrsuit {
  display: none;
  text-shadow: 0 3px 1px #8c869089;
}

button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 716px) {
  header {
    width: 100%;
    margin-bottom: 40px;
  }

  .App-header {
    padding-top: 3vh;
  }

}