/* General navbar styling */
header {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  background: rgb(242, 234, 229);
  height: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  position: relative;
  overflow: visible; /* Allows emblem to extend below the navbar */
}

nav {
  display: flex;
  justify-content: space-between; /* Align left, center (emblem), and right links */
  align-items: center;
  width: 100%;
  padding: 0 2em; /* Padding for spacing */
}

.left-container,
.right-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7vh;
  flex: 1; /* Equal space for left and right links */
}

.left-container {
  justify-content: flex-end; /* Push links closer to the emblem */
}

.right-container {
  justify-content: flex-start; /* Push links closer to the emblem */
}

.emblem-container {
  display: flex; /* Ensure the emblem is part of the flex layout */
  justify-content: center;
  align-items: center;
  flex: 0 0 auto; /* Prevent resizing */
  position: relative; /* Allows controlled positioning */
  z-index: 1;
}

.emblem {
  background-image: url(../assets/Logo3rddraft.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 15vw; /* Adjust size */
  height: 15vw;
  position: relative;
  top: 4em; /* Push emblem down slightly so it overflows below the navbar */
}

/* Styling for links */
nav a,
nav h3 {
  font-family: "new-kansas", sans-serif;
  text-shadow: 0 1px 0.6px #8c869089;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 1em;
  color: rgb(113, 41, 162);
  font-weight: 600;
  font-size: 1.1em;
}

nav a:hover {
  text-decoration: underline;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  background-color: rgb(242, 234, 229);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  flex-direction: column;
  border-radius: 4px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.search-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Burger Menu */
.burger-menu-container {
  display: none; /* Hidden on larger screens */
  flex-direction: column;
  align-items: center;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 35px;
  height: 25px;
  z-index: 3;
}

.burger-menu div {
  height: 3px;
  background-color: rgb(113, 41, 162);
  margin: 3px 0;
}

.mobile-dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 234, 229);
  position: absolute;
  top: 12em;
  width: 100%;
  z-index: 3;
}

.mobile-dropdown-menu .menu-link {
  padding: 1em;
  text-align: center;
  color: rgb(113, 41, 162);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}

.mobile-dropdown-menu .menu-link:hover {
  text-decoration: underline;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .left-container,
  .right-container {
    display: none; /* Hide regular desktop links */
  }

  .emblem-container {
    position: absolute;
    top: 8vh;
    left: 12%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .emblem {
    width: 30vw;
    height: 30vh;
  }

  .burger-menu-container {
    display: flex;
    margin: 5% 10% 2% 0;
  }

  .burger-menu {
    display: flex;
  }
}
