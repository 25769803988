.Footer {
    position: relative;
    background: white;
    width: 100%;
    left: 0;
    bottom: 0;
    min-height: 60px;
    max-height: 20px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 716px) {
    .Footer {
    position: fixed;
    }
}