.blog {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 250px;
}

.featured {
    margin: 0 2% 10px 2%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.featured_preview {
    display: flex;
    background-color: rgb(255, 247, 243);
    border-radius: 38px;
    box-shadow: 1px 1px 10px;
    height: 495px;
    overflow: hidden;
}

.featured_section_title {
    margin: 0 0 0px 10px;
    color: rgb(246, 240, 235);
    text-shadow: 1px 1px 1px;
    letter-spacing: 2px;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none !important;
    transition: color 0.3s;
}

a:hover {
    color: darkblue;
}


.blog_preview {
    border: 1px solid #e0e0e0;
    border-radius: 38px;
    overflow: hidden;
}

.featured_image {
    min-width: 45vw !important;
    max-width: 45vw !important;
    padding-left: 0 !important;
    height: auto !important;
    object-fit: cover !important; 
}

.featured_content {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 2%;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
}

.featured_author {
    display: flex;
    flex-direction: row;
    align-content: left;
    margin-bottom: 10px;
}

.featured_author_image {
    width: 3.5vw;
    height: auto;
}

.featured_author p {
    font-size: 16px;
    align-items: center;
    margin-left: 12px;
}

.featured_title {
    font-size: 2.1em;
    line-height: 1.2;
}

.featured_subtitle p {
    margin-top: 5px;
    font-size: 1.1em;
}

.featured_date p {
    font-size: .8em;
    margin-top: 5px;
}


.featured_preview a {
    color: inherit;
}

.search-bar {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }
  
  .search-input {
    width: 60%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-results {
    display:flex;
    position: absolute;
    z-index: 1;
    background: white;
    width: 60%;
    margin: 0px 20%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    text-align: start;
  }
  
  .search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .search-results li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .search-results li:last-child {
    border-bottom: none;
  }
  
  .search-results li:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  

.latest {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* margin-left: 2%; */
    margin-bottom: 75px;
}

.latest_section_title {
    margin: 0 0 0 10px;
    font-size: 32px;
    letter-spacing: 1.28px;
    color: rgb(246, 240, 235);
    text-shadow: .9px .9px 1px;
}

.latest_card_link {
    margin-bottom: 30px;
    text-decoration: none;
    color: inherit;
}

.latest_card {
    border: 1px solid #e0e0e0;
    border-radius: 28px;
    overflow: scroll;
    background-color: rgb(255, 247, 243);
    box-shadow: 1px 1px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 28px;
    width: 95%;
    height: 400px;
}

.latest_card_image {
    object-fit: cover;
    width: 100%;
    max-height: 60%;
}

.latest_card_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 35px 15px 35px;
    height: 100%;
}

.latest_card_title {
    font-weight: 500;
    font-size: .78em;
    font-kerning: auto;
}

.latest_author {
    display: flex;
    flex-direction: row;
}

.latest_author_image {
    width: 3.5vw;
    height: auto;
}

.latest_author p {
    font-size: 14px;
    align-items: center;
    margin-left: 5px;
}

.latest_date {
    margin-left: 5vw;
    align-self: center;
}

.featured_author_image_wrapper,
.latest_author_image_wrapper {
    border: 3px solid #9e4caf;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.featured_author_image,
.latest_author_image {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.category_section_title {
    margin: 250px 0 0px 10px;
    font-size: 32px;
    letter-spacing: 1.28px;
    color: rgb(246, 240, 235);
    text-shadow: .9px .9px 1px;
}

@media (max-width: 850px) {
    .blog {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 70px;
    }

    .featured {
        margin: 0 0 0 0;
        text-align: left;
        display: flex;
        /* flex-direction: column; */
        /* gap: 16px; */
    }

    .featured_section_title {
        margin-top: 0;
    }

    .featured_preview {
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 247, 243);
        border-radius: 0;
        box-shadow: 1px 1px 10px;
        height: auto;
        /* width: 100%; */
        overflow: hidden;
    }

    .featured_image {
        max-width: 100% !important;
        max-height: 40vh;
        object-fit: cover;
        margin-bottom: 15px;
    }

    .featured_title {
        font-size: 1.3em;
        font-weight: bold;
    }

    .featured_subtitle {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .featured_date p {
        font-size: 14px;
        margin: 0 0 20px 0;
    }

    .latest_cards_container {        
        grid-template-columns: 0fr;
        height: auto;
        width: 100%;
        margin: 0;
    }

    .latest_card {
        width: 100%;
        height: auto;
        margin-bottom: 2%;
        border-radius: 0px;
    }

    .latest_card_link {
        margin-right: 0%;
        text-decoration: none;
        color: inherit;
    }

    .latest_card_image {
        object-fit: cover;
        width: 100%;
        height: 25vh;
    }

    .latest_card_title {
        font-size: 15px;
    }

    .featured_author_image_wrapper,
    .latest_author_image_wrapper {
        border: 3px solid #9e4caf;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-top: 10px;
    }

    .latest_card_content {
        justify-content: space-evenly;
        height: auto;
    }

    .featured_author_image,
    .latest_author_image {
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .latest_author {
        display: flex;
        flex-direction: row;
    }

    .latest_author p {
        font-size: 14px;
        align-items: center;
        justify-content: space-between;
        margin-left: 5px;
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0);
        padding-left: calc(var(--bs-gutter-x) * 0);
    }
}